import { createSlice } from '@reduxjs/toolkit'
import serviceConfig from '../../.config'
import qs from 'qs'

interface ServiceInfo {
  id: string
  title: string
  src: string
  webVersion?: string
  appVersion?: {
    and: string
    ios: string
  }
}

const queryData: any = qs.parse(window.location.search.replace(/^\?/, ''))
const userAgent = navigator.userAgent

const isIOS = /ipad|iphone/i.test(userAgent)
const isAndroid = /android/i.test(userAgent)
const isInApp = /\/tmap\//i.test(userAgent)
const serviceInfo = serviceConfig.services.find(serviceItem => serviceItem.id === 'general') as ServiceInfo

const releaseAppVersion = (serviceInfo.appVersion && (isIOS ? serviceInfo.appVersion?.ios : serviceInfo.appVersion?.and)) || '8.11.0'
const getAppVersion = (userAgent: string, isInApp: boolean, isIOS: boolean, isAndroid: boolean): string => {
  let appVersion
  if (isInApp && isIOS) appVersion = userAgent.replace(/.*\/ios\/([\d.]*).*/i, '$1')
  else if (isInApp && isAndroid) appVersion = userAgent.replace(/.*\/android\/([\d.]*).*/i, '$1')
  else appVersion = process.env.REACT_APP_TMAP_APP_VERSION || ''
  const isDetected = appVersion && appVersion !== userAgent
  if (isDetected) return appVersion
  else if (isInApp) return '1.0.0'
  else return releaseAppVersion
}

interface AppState {
  accessKey: string
  serviceInfo: ServiceInfo
  serviceRootPath: string
  userAgent: string
  initialLocation: {
    href: string
    pathname: string
    search: string
    hash: string
    queryData: Nullable<any>
    detectedSchemeParams: any
  }
  isIOS: boolean
  isAndroid: boolean
  appVersion: string
  deviceInfo: {
    carrierName: string
    deviceId: string
  }
  releaseAppVersion: string
  euk: string
  sessionId: string
}

const initialState: AppState = {
  accessKey: '',
  serviceInfo,
  serviceRootPath: `${process.env.PUBLIC_URL}/web/general`,
  userAgent,
  initialLocation: {
    href: window.location.href,
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
    queryData,
    detectedSchemeParams: null,
  },
  isIOS,
  isAndroid,
  appVersion: getAppVersion(userAgent, isInApp, isIOS, isAndroid),
  releaseAppVersion,
  deviceInfo: {
    carrierName: '',
    deviceId: '',
  },
  euk: '',
  sessionId: '',
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAccessKey(state, action) {
      state.accessKey = action.payload
    },
    setCarrierName(state, action) {
      state.deviceInfo.carrierName = action.payload
    },
    setDeviceId(state, action) {
      state.deviceInfo.deviceId = action.payload
    },
    setEuk(state, action) {
      state.euk = action.payload
    },
    setSessionId(state, action) {
      state.sessionId = action.payload
    },
  },
})

export { appSlice }

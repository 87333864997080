import React, { CSSProperties, forwardRef, memo, PropsWithChildren } from 'react'
import { classNames } from '@tmap-web-lib/utils'

type Props = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  imageSrc: string;
}>

const LoadingFullIndicator = memo(forwardRef<HTMLDivElement, Props>(
  function LoadingFullIndicator(props, ref) {
    const { children, className, style, imageSrc } = props
    return (
      <div className={classNames('loading_indicator', className)} style={style} ref={ref}>
        {children ? children : <img src={imageSrc} width="50px" height="50px" alt=""/>}
      </div>
    )
  }
))

export { LoadingFullIndicator }

import { Route, Switch } from 'react-router-dom'
import { GeneralRouteProps, routeConfigs } from './routes-configs'
import Home from '../pages'
import React from 'react'

const GeneralRoute = (props: GeneralRouteProps) => {
  return (
    <Route path={props.path} exact={props.exact}>
      {props.component ? <props.component /> : null}
    </Route>
  )
}

const MainRouter = () => {
  return (
    <Switch>
      <Route exact={true} path="/">
        <Home />
      </Route>
      {routeConfigs.map((config) => (
        <GeneralRoute {...config} />
      ))}
    </Switch>
  )
}

export { MainRouter }

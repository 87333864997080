import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

type Props = PropsWithChildren<{
  elementId?: string;
  rootElementSelector?: string;
}>

let containerElement: HTMLElement | null

function LoadingGlobalIndicatorPortal(props: Props) {
  const {
    elementId = 'loading-global-indicator-portal',
    rootElementSelector = '#root',
    children,
  } = props

  if (!containerElement) {
    const rootElement = document.querySelector(rootElementSelector)
    if (rootElement == null) {
      throw Error(`rootElement(${rootElementSelector}) not found.`)
    }
    containerElement = document.createElement('div')
    containerElement.id = elementId
    document.body.insertBefore(containerElement, rootElement)
  }

  return createPortal(children, containerElement)
}

export { LoadingGlobalIndicatorPortal }

import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Circular from './assets/images/circular.svg'
import { Container } from './components'
import { LoadingGlobalIndicator } from './components/LoadingIndicator'
import { MainRouter } from './routes'
import { store } from './store'

const serviceRootPath = store.getState().app.serviceRootPath

function App() {
  return (
    <>
      <LoadingGlobalIndicator imageSrc={Circular} />
      <Router basename={serviceRootPath}>
        <Suspense fallback="">
          <Container>
            <MainRouter />
          </Container>
        </Suspense>
      </Router>
    </>
  )
}

export default App

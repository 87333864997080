import '../../../common/styles/common/_index.scss'
import React from 'react'
import { routeConfigs } from '../routes/routes-configs'

const Home = () => {
  return (
    <div className="wrap">
      <div className="logo" />
        <h2 className="build_number">All vertical service page</h2>
        <ul className="service_list">
          {routeConfigs.map(routeConfig =>
            routeConfig.bridge ? (
              <li className="service_list_item" key={routeConfig.key}>
                {routeConfig.key}-<a href={`/view/web/general${routeConfig.path}`} className="link">{`/view/web/general${routeConfig.path}`}</a>
              </li>
            ) : null
          )}
        </ul>
    </div>
  )
}

export default Home

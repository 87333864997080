import { debounce } from 'lodash-es'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { CSSTransition } from 'react-transition-group'
import { LoadingFullIndicator, LoadingGlobalIndicatorPortal } from './index'

interface Props {
  useDelay?: boolean
  imageSrc: string
  rootElementSelector?: string
}

const LoadingGlobalIndicator = memo(function LoadingGlobalIndicator(props: Props) {
  const { useDelay = false, imageSrc, rootElementSelector = '#root' } = props
  return (
    <LoadingGlobalIndicatorPortal rootElementSelector={rootElementSelector}>
      <PromiseTrackingIndicator useDelay={useDelay} imageSrc={imageSrc} />
    </LoadingGlobalIndicatorPortal>
  )
})

interface IndicatorProps {
  useDelay?: boolean
  imageSrc: string
}

const PromiseTrackingIndicator = memo(function PromiseTrackingIndicator(props: IndicatorProps) {
  const { useDelay = false } = props
  const { promiseInProgress } = usePromiseTracker()
  const [isWorking, setWorking] = useState(promiseInProgress)
  const changeDebounce = useMemo(
    () =>
      debounce(
        isWorking => {
          setWorking(isWorking)
        },
        500,
        { leading: !useDelay },
      ),
    [useDelay],
  )

  useEffect(() => {
    changeDebounce(promiseInProgress)
    return () => changeDebounce.cancel()
  }, [changeDebounce, promiseInProgress])

  return <Indicator {...props} isShowing={isWorking} />
})

const Indicator = memo(function Indicator(props: { duration?: number; isShowing: boolean; imageSrc: string }) {
  const { duration = 200, isShowing, imageSrc } = props
  const ref = useRef(null)

  return (
    <CSSTransition classNames="fade-in" unmountOnExit={true} timeout={duration} in={isShowing} nodeRef={ref}>
      <LoadingFullIndicator imageSrc={imageSrc} ref={ref} />
    </CSSTransition>
  )
})

export * from 'react-promise-tracker'
export { LoadingGlobalIndicator }

import axios from 'axios'
import formatDate from 'date-fns/format'
import uaParser from 'ua-parser-js'
import { LogBox as logBoxFactory, LogBoxInstance } from './lib/mix-logbox'
import { TmapLogBuilder } from './tmap-log-builder'
import { LogBoxConfig, LogBoxInstanceMethod, TmapLogResponseData, TmapLogType } from './types'

const baseProps: [Exclude<LogBoxInstanceMethod, 'addCustomField'>, string][] = []
const config = {} as LogBoxConfig
let tlaUrl = ''

export function init(logBoxConfig: LogBoxConfig) {
  Object.assign(config, logBoxConfig)

  const { browser, device, os } = uaParser(navigator.userAgent)
  baseProps.push(
    ['setOs_name', os.name || ''],
    ['setOs_version', os.version || ''],
    ['setManufacturer', device.vendor || ''],
    ['setDevice_model', device.model || ''],
    ['setBrowser_name', browser.name || ''],
    ['setBrowser_version', browser.version || ''],
    ['setLanguage_code', navigator.language],
  )

  tlaUrl = logBoxConfig.logBoxEnv.tlaUrl
    ? logBoxConfig.logBoxEnv.tlaUrl
    : logBoxConfig.env !== 'PROD'
      ? 'https://tla-stg.tmap.co.kr:8988'
      : 'https://tla.tmap.co.kr:8988'
}

export function tmapLogBuilder(type: TmapLogType): LogBoxInstance {
  const { logBoxEnv, appEnv } = config
  const builder = logBoxFactory({
    ...logBoxEnv,
    tlaUrl,
  })

  switch (type) {
    case 'app':
      builder.selectLogTypeAsApp()
      break
    case 'event':
      builder.selectLogTypeAsEvent()
      builder
      .setPage_type('')
      .setPage_id('')
      .setSection_id('')
      .setAction_id('')
      break
    case 'expose':
      builder.selectLogTypeAsExpose()
      builder
      .setPage_type('')
      .setPage_id('')
      break
  }

  builder
  .setApp_version(appEnv.appVersion)
  .setCarrier_name(appEnv.carrier)
  .setDevice_id(appEnv.deviceId)
  .setSession_id(appEnv.sessionId)
  .setIp(appEnv.ip)

  baseProps.forEach(prop => builder[prop[0]](prop[1]))

  const localTime = formatDate(
    new Date((new Date().getTime() + (new Date().getTimezoneOffset() * 60 * 1000)) + (540 * 60 * 1000)),
    'yyyyMMddHHmmssSSS',
  );
  const screen = window.screen
  const runtimeProps: [Exclude<LogBoxInstanceMethod, 'addCustomField'>, string][] = [
    ['setUrl', window.location.href],
    ['setReferrer', document.referrer],
    ['setDocument_title', document.title],
    ['setResolution', `${screen.width}*${screen.height}`],
    ['setScreen_width', String(screen.width)],
    ['setScreen_height', String(screen.height)],
    ['setLocal_time', localTime],
    ['setNetwork_type', ''],
  ]
  runtimeProps.forEach(prop => builder[prop[0]](prop[1]))

  return builder
}

export function sendTmapLog(builder: LogBoxInstance) {
  return axios.get<TmapLogResponseData>(`${builder.getTlaURL()}${builder.getTlaPath()}${builder.getJSONStringEncodeURI()}`)
}

export function TmapLog(type: TmapLogType) {
  return new TmapLogBuilder(type)
}

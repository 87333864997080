import 'core-js/features/object/from-entries'
import 'react-app-polyfill/stable'
import './styles/index.scss'

import { init as initLogbox } from '@tmap-web-lib/logbox-client'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { toLower } from 'lodash-es'
import { init as initMixpanel } from 'mixpanel-browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as StoreProvider } from 'react-redux'
import { UAParser } from 'ua-parser-js'
import App from './App'
import { appActions, store } from './store'

const isPrd = process.env.REACT_APP_BUILD_ENV === 'PRD'
const ua = UAParser()

TmapApp.getUserSetting({ key: 'local.server_type' }).then(serviceType => {
  if (serviceType === 'rtg') {
    const href = window.location.href
    if (href.startsWith('https://m.')) {
      window.location.replace(href.replace(/^(https:\/\/)(m.*?\.)?(.*)/, '$1m-rtg.$3'))
      return
    } else if (href.startsWith('https://m-stg.')) {
      window.location.replace(href.replace(/^(https:\/\/)(m.*?\.)?(.*)/, '$1m-dtg.$3'))
      return
    }
  }
  init()
})

function init() {
  Promise.all([TmapApp.getAccessKey(), TmapApp.getDeviceId(), TmapApp.getCarrierName(), TmapApp.getEUK(), TmapApp.getSessionId()]).then(
    ([accessKey, deviceId, carrierName, euk, sessionId]) => {
      store.dispatch(appActions.setAccessKey(accessKey))
      store.dispatch(appActions.setDeviceId(deviceId || ''))
      store.dispatch(appActions.setCarrierName(carrierName || ''))
      store.dispatch(appActions.setEuk(euk || ''))
      store.dispatch(appActions.setSessionId(sessionId || ''))

      initLogbox({
        env: isPrd ? 'PROD' : 'STG',
        logBoxEnv: {
          logVersion: `1.0-${toLower(process.env.REACT_APP_BUILD_ENV)}`,
          appId: 'tmap',
          serviceId: 'my',
          token: isPrd ? 'F5CD39ECE530' : '3AF19605D63E',
        },
        appEnv: {
          appVersion: store.getState().app.appVersion,
          deviceId,
          carrier: carrierName,
          ip: '',
          sessionId,
        },
      })

      initMixpanel(isPrd ? 'cafac3a4974386155257d177fc48437a' : '7600c413c33bbd7e3742b10f1a522f0d', {
        debug: !isPrd,
        ignore_dnt: !isPrd,
        loaded: mixpanel => {
          mixpanel.identify(euk)
          mixpanel.register({
            $app_version: TmapApp.env.appVersion,
            $carrier: carrierName,
            $manufacterer: ua.device.vendor,
            $model: ua.device.model,
            $os_version: ua.os.version,
            device_id: deviceId,
            session_id: sessionId,
            accessKey: accessKey,
            userAgent: navigator.userAgent,
            tmap_env: TmapApp.env,
          })
        },
      })

      ReactDOM.render(
        <React.StrictMode>
          <StoreProvider store={store}>
            <App />
          </StoreProvider>
        </React.StrictMode>,
        document.getElementById('root'),
      )
    },
  )
}

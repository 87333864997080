import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { appSlice } from './app'

const rootReducer = combineReducers({
  app: appSlice.reducer,
})

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export const appActions = appSlice.actions


export { store }
export * from './app'

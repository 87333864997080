import isArray from 'lodash/isArray'
import upperFirst from 'lodash/upperFirst'
import { sendTmapLog, tmapLogBuilder } from './core'
import { LogBoxInstance } from './lib/mix-logbox'
import { TmapLogType } from './types'

type SetterBaseName<P extends string> = P extends `set${infer NAME}` ? Uncapitalize<NAME> : never;
type GetterBaseName<P extends string> = P extends `get${infer NAME}` ? Uncapitalize<NAME> : never;
type AdderBaseName<P extends string> = P extends `add${infer NAME}` ? Uncapitalize<NAME> : never;
type SetterFullName<P extends string> = `set${Capitalize<P>}`
type AdderFullName<P extends string> = `add${Capitalize<P>}`
type Setters = SetterBaseName<keyof LogBoxInstance>
type Getters = GetterBaseName<keyof LogBoxInstance>
type Adders = AdderBaseName<keyof LogBoxInstance>
type SetterParameters<P extends Setters> = Parameters<LogBoxInstance[SetterFullName<P>]>
type AdderParameters<P extends Adders> = Parameters<LogBoxInstance[AdderFullName<P>]>
type AdderRestParameters<P extends Adders> = AdderParameters<P>[]

export class TmapLogBuilder {
  private readonly logboxBuilder

  constructor(type: TmapLogType) {
    this.logboxBuilder = tmapLogBuilder(type)
  }

  set<K extends Setters>(key: K, ...value: SetterParameters<K>) {
    const prop = `set${upperFirst(key)}` as keyof LogBoxInstance
    this._invokeSetterMethod(prop, ...value)
    return this
  }

  add<K extends Adders>(key: K, ...value: AdderParameters<K> | AdderRestParameters<K>) {
    const prop = `add${upperFirst(key)}` as keyof LogBoxInstance
    if (isArray<string>(value[0])) {
      (value as AdderRestParameters<K>).forEach(record => this._invokeSetterMethod(prop, ...record))
    } else {
      this._invokeSetterMethod(prop, ...(value as AdderParameters<K>))
    }
    return this
  }

  get<K extends Getters>(key: K) {
    const prop = `get${upperFirst(key)}` as keyof LogBoxInstance
    const method = this._pickMethod(prop)
    return method.call(this.logboxBuilder)
  }

  send() {
    return sendTmapLog(this.logboxBuilder)
  }

  setPageId(value: string) {
    return this.set('page_id', value)
  }

  setPageType(value: string) {
    return this.set('page_type', value)
  }

  setActionId(value: string) {
    return this.set('action_id', value)
  }

  setEuk(value: string) {
    return this.set('euk', value)
  }

  addCustomField(key: string, value: string) {
    return this.add('customField', key, value)
  }

  private _pickMethod<K extends keyof LogBoxInstance>(key: K) {
    return this.logboxBuilder[key as keyof LogBoxInstance] as any
  }

  private _invokeSetterMethod<K extends keyof LogBoxInstance>(key: K, ...value: Parameters<LogBoxInstance[K]>) {
    const method = this._pickMethod(key)
    method.apply(this.logboxBuilder, value)
  }
}

export type { Setters as LogBoxAttributes }

import { FC, lazy } from 'react'
import { RouteProps } from 'react-router-dom'

export interface GeneralRouteProps extends RouteProps {
  key: string
  component?: FC
  title?: string
  auth?: boolean
  bridge?: boolean
}

export const routeConfigs: GeneralRouteProps[] = [
  {
    key: 'history',
    path: '/usage-history',
    exact: true,
    component: lazy(() => import('../pages/usage-history')),
    bridge: true,
  },
  {
    key: 'close',
    path: '/close',
    exact: true,
    bridge: true,
    component: lazy(() => import('../pages/close-notice')),
  },
]

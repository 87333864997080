import { isUndefined } from 'lodash-es'
import React from 'react'
import { ButtonProps } from './types'

function Button({
  type = 'button',
  group = 'basic',
  variant = 'default',
  className = '',
  innerWrap = true,
  invisible,
  children,
  ...attrs
}: ButtonProps) {
  let buttonClass = `btn_${group}_${variant}`
  if (className) {
    buttonClass = `${buttonClass} ${className}`
  }
  let innerClassName
  if (isUndefined(invisible)) {
    innerClassName = group === 'image' ? 'g_invisible' : `btn_${group}_inner`
  } else {
    innerClassName = invisible ? 'g_invisible' : `btn_${group}_inner`
  }
  return (
    <button
      {...attrs}
      type={type}
      className={buttonClass}
    >
      {innerWrap ? (
        <span className={innerClassName}>{children}</span>
      ) : (
        <>{children}</>
      )}
    </button>
  )
}

export { Button }

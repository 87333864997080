import React, {MouseEvent, PropsWithChildren, useCallback, useEffect, useState} from 'react'
import { Button } from '../Button'
import { classNames } from '@tmap-web-lib/utils'

interface Props extends PropsWithChildren<{}> {
  className?: string;
  title?: string
  useBack?: boolean;
  onBackClick?: (event: MouseEvent) => void;
}

function Header(props: Props) {
  const { className = '', useBack, title, children, onBackClick } = props
  const [isScroll, setScroll] = useState<boolean>(false)

  const onScroll = useCallback(() => {
    return window.scrollY > 0 ? setScroll(true) : setScroll(false)
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <div className={classNames('header type_fix', className, isScroll ? 'type_scroll' : '')}>
      <div className="header_main_wrap">
        {useBack &&
          <Button group="image" variant="back" onClick={onBackClick}>
            이전 페이지
          </Button>
        }
        <h1 className="header_main_title">{title}</h1>
        {children}
      </div>
    </div>
  )
}

export { Header }
